'use client';
import { Alert, Box, Container, Divider, Grid, Zoom } from '@mui/material';
import isEmail from 'is-email';
import Image from 'next/image';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useTranslation } from '@/app/i18n/client';
import SplashScreenAnimation from '@/components/SplashScreenAnimation.svg';
import SplashScreenLogo from '@/components/SplashScreenLogo.svg';
import { removeCookies } from '@/service/removeCookies';
import { isSafari } from '@/utils';

import { login, loginWithSSO } from './actions';
import { LoginWithPasswordForm } from './parts/LoginWithPasswordForm';
import { LoginWithSSO } from './parts/LoginWithSSO';

export default function Login() {
  const [showLogo, setShowLogo] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSSO, setLoadingSSO] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [loginWithSSOSelected, setLoginWithSSOSelected] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const validEmail = !!email && isEmail(email);

  const params = useParams();
  const lng = params?.lng ?? 'en';

  const router = useRouter();

  const searchParams = useSearchParams();
  const errorParam = searchParams?.get('error');

  const { t } = useTranslation(typeof lng === 'string' ? lng : 'da');

  const getSignInUrl = () => {
    const redirectUrl = searchParams?.get('redirectUrl');

    if (redirectUrl) return redirectUrl;
    return `/${lng}`;
  };

  const handleLoginWithPassword = async (formData: FormData) => {
    const { error: loginError } = await login(formData, getSignInUrl());
    if (loginError) {
      setError(loginError);
      setLoading(false);
    }
  };

  const getSSORedirectURL = () => {
    let url =
      process?.env?.NEXT_PUBLIC_SITE_URL ?? // Set in production env.
      process?.env?.NEXT_PUBLIC_VERCEL_BRANCH_URL ?? // Automatically set by Vercel.
      'http://localhost:3000/';
    // Make sure to include `https://` when not localhost.
    url = url.startsWith('http') ? url : `https://${url}`;
    // Make sure to include a trailing `/`.
    url = url.endsWith('/') ? url : `${url}/`;
    return `${url}${typeof lng === 'string' ? lng : 'da'}/auth/callback`;
  };

  const handleLoginWithSSO = async () => {
    setLoadingSSO(true);
    const domain = email.split('@').pop();

    if (!domain || domain.length == 0) {
      setLoadingSSO(false);
      return; // todo handle error
    }
    const callbackRedirectUrl = getSSORedirectURL();
    const { data: ssoRedirectUrl, error: signInError } = await loginWithSSO(domain, callbackRedirectUrl);

    if (signInError) {
      setError(signInError);
      setLoading(false);
      return;
    }

    if (ssoRedirectUrl) router.push(ssoRedirectUrl);
  };

  useEffect(() => {
    if (errorParam) {
      setError(errorParam);
    }
  }, [errorParam]);

  useEffect(() => {
    if (errorParam) {
      setError(errorParam);
    }
  }, [errorParam]);

  useEffect(() => {
    // Nice to do in case of malformed JWTs
    // Need to do it inside of useEffect otherwise it triggers on each onChange event from text fields
    removeCookies();

    // This is a hack for isSafari() to be evaluated clientside instead of serverside due to ssr hydration
    setShowLogo(true);
  }, []);

  return (
    <Container
      sx={{
        height: '100svh',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Grid container justifyContent="center" spacing={{ xs: 4, md: 6 }}>
        <Grid item xs={8} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
          {showLogo && (
            <Image
              priority
              style={{ height: 'auto', maxWidth: '300px', marginTop: isSafari() ? undefined : '-100px' }}
              alt="proprty.ai logo"
              src={isSafari() ? SplashScreenLogo : SplashScreenAnimation}
            />
          )}
        </Grid>
        <Grid
          style={{ display: 'flex', justifyContent: 'center' }}
          xs={12}
          sm={8}
          md={6}
          item
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <LoginWithSSO
            loading={loadingSSO}
            setLoading={setLoadingSSO}
            handleLoginWithSSO={handleLoginWithSSO}
            loginWithSSOSelected={loginWithSSOSelected}
            setLoginWithSSOSelected={setLoginWithSSOSelected}
            email={email}
            setEmail={setEmail}
            validEmail={validEmail}
            lng={typeof lng === 'string' ? lng : 'da'}
          />
          {!loginWithSSOSelected && (
            <>
              <Zoom style={{ transitionDelay: '50ms' }} in>
                <Divider sx={{ mt: 3, mb: 1 }}>{t('or')}</Divider>
              </Zoom>
              <LoginWithPasswordForm
                loading={loading}
                setLoading={setLoading}
                handleLoginWithPassword={handleLoginWithPassword}
                email={email}
                setEmail={setEmail}
                validEmail={validEmail}
                password={password}
                setPassword={setPassword}
                signInUrl={getSignInUrl()}
                lng={typeof lng === 'string' ? lng : 'da'}
              />
            </>
          )}
          <Box sx={{ mt: 2 }}>{error && <Alert severity="error">{error}</Alert>}</Box>
        </Grid>
      </Grid>
    </Container>
  );
}
