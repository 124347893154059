import Login from '@mui/icons-material/Login';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField, Typography, Zoom } from '@mui/material';
import va from '@vercel/analytics';

import { useTranslation } from '@/app/i18n/client';
type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  handleLoginWithPassword: (formData: FormData) => Promise<void>;
  email: string;
  setEmail: (email: string) => void;
  validEmail: boolean;
  password: string;
  setPassword: (pw: string) => void;
  signInUrl: string;
  lng: string;
};

export const LoginWithPasswordForm: React.FC<Props> = ({
  loading,
  setLoading,
  handleLoginWithPassword,
  email,
  setEmail,
  validEmail,
  password,
  setPassword,
  signInUrl,
  lng,
}) => {
  const validPassword = password && password.length > 0;
  const loginButtonDisabled = !validEmail || !validPassword;

  const { t } = useTranslation(lng);

  return (
    <Grid
      style={{ display: 'flex', justifyContent: 'center' }}
      component="form"
      action={handleLoginWithPassword}
      id="loginForm"
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
    >
      <Box>
        <Zoom in>
          <Typography sx={{ mb: 2 }} fontSize={34} fontWeight={'bold'} component={'h1'}>
            {t('logIn')}
          </Typography>
        </Zoom>
        <Zoom style={{ transitionDelay: '50ms' }} in>
          <Box mb={2}>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              name="email"
              placeholder="you@example.com"
              required
              size="medium"
              fullWidth
              focused
            />
          </Box>
        </Zoom>
        <Zoom style={{ transitionDelay: '100ms' }} in>
          <Box mb={2}>
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label={t('password')}
              type="password"
              name="password"
              placeholder="••••••••"
              required
              size="medium"
              fullWidth
              focused
            />
          </Box>
        </Zoom>
        <input type="hidden" name="redirectPath" value={signInUrl} />
        <input type="hidden" name="lng" value={lng} />
        <Zoom style={{ transitionDelay: '150ms' }} in>
          <Box justifyContent="center" display="flex" flexDirection="column" gap={1}>
            <LoadingButton
              disabled={loginButtonDisabled}
              loading={loading}
              loadingPosition="start"
              startIcon={<Login />}
              onClick={() => {
                va.track('Clicked log in');
                setTimeout(() => setLoading(true));
              }}
              fullWidth
              variant="contained"
              type="submit"
              size="large"
            >
              {t('logInWithPassword').toUpperCase()}
            </LoadingButton>
          </Box>
        </Zoom>
      </Box>
    </Grid>
  );
};
